import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Cookie from 'js-cookie'
import classNames from 'classnames'

import { get } from 'lib/network/fetchers/everlane'
import { publishEvent } from 'lib/events/tracking'
import useSession from 'lib/network/hooks/useSession'
import { isInternal } from 'lib/routing'
import useMenus from 'lib/network/hooks/useMenus'
import { recursiveSubMenuSearch } from 'lib/helpers/navigationV2'
import useAppConfig from 'lib/network/hooks/useAppConfig'

import NavigationContext, { GiftcardDesign } from 'contexts/NavigationContext'
import OverlayProductContext, { OverlaySource } from 'contexts/OverlayProductContext'

import {
  ACTIVE_GENDER_MENU,
  TOP_LEVEL_MENU,
  DEFAULT_WOMEN_MENU,
  ACTIVE_SUBMENU_TITLE,
} from 'components/Layout/Navbar/Constants'

import SkipLink from 'components/Layout/SkipLink'
import GiftcardModal from 'components/products/ProductCTA/GiftcardModal'

import Menu from 'types/Menu'
import Event from 'types/Event'
import Cookies from 'types/Cookies'

import styles from './styles.module.scss'

const BuilderComponent = dynamic(() => import('components/core/BuilderComponent'), { ssr: false })
const FlyoutCart = dynamic(() => import('components/cart/FlyoutCart'), { ssr: false })
const Footer = dynamic(() => import('components/Layout/Footer'), { ssr: false })
const GDPRModal = dynamic(() => import('./GDPRModal'), { ssr: false })
const Trackers = dynamic(() => import('components/Trackers'), { ssr: false })
const Navbar = dynamic(() => import('./Navbar'))
const ProductOverlay = dynamic(() => import('components/products/ProductOverlay'), { ssr: false })
const MultiProductOverlay = dynamic(() => import('components/products/MultiProductOverlay'), {
  ssr: false,
})

const ShadowUserBanner = ({ session, stopShadowingUser }) => {
  const { firstName, lastName } = session?.user || {}

  return (
    <div className={styles['shadow-user-banner']}>
      <p className={styles['shadow-user-banner__text']}>
        <span className={styles['shadow-user-banner__text--bold']}>Debug: </span>
        {firstName} {lastName} Please{' '}
        <button
          type="button"
          className={styles['shadow-user-banner__button']}
          onClick={async () => {
            await stopShadowingUser()
            global.window.location.reload()
          }}
        >
          Stop Switching Users
        </button>{' '}
        after debugging
      </p>
    </div>
  )
}

const Layout = ({
  children = null,
  collection = null,
  product = null,
  bodyContentClass = null,
  showBanner = true,
  showNavbar = true,
  showFooter = true,
  showMenuIcon = true,
  showCartIcon = true,
  showFlyoutCart = true,
  triggerAuthModal = false,
} = {}) => {
  const [isGDPRModalOpen, setIsGDPRModalOpen] = useState(false)
  const [isAddToCartModalOpen, setIsAddToCartModalOpen] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)
  const [overlaySource, setOverlaySource] = useState<OverlaySource>(null)
  const [overlayPermalink, setOverlayPermalink] = useState<string>(null)
  const [selectedOverlaySize, setSelectedOverlaySize] = useState<string>(null)
  const [overlayError, setOverlayError] = useState<string>(null)
  const [overlayPreviewedSize, setOverlayPreviewedSize] = useState<string>(null)
  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useState(false)
  const [isGiftcardModalOpen, setIsGiftcardModalOpen] = useState(false)
  const [giftcardModalEditingData, setGiftcardModalEditingData] = useState()
  const [isBannerSticky, setIsBannerSticky] = useState(false)
  const [isShadowingUser, setIsShadowingUser] = useState(false)
  const [isRegionDropdownOpen, setIsRegionDropdownOpen] = useState(false)
  const [genderMenu, setGenderMenu] = useState(null)
  const [genderMenuTitle, setGenderMenuTitle] = useState<string>(TOP_LEVEL_MENU.women)
  const [womenMenu, setWomenMenu] = useState(null)
  const [menMenu, setMenMenu] = useState(null)
  const [nonGenderMenu, setNonGenderMenu] = useState(null)
  const [subMenu, setSubMenu] = useState(null)
  const [subMenuTitle, setSubMenuTitle] = useState<string>(null)
  const [showTopLevelMenu, setShowTopLevelMenu] = useState<boolean>(true)
  const [flyoutCartRenderedAlready, setFlyoutCartRenderedAlready] = useState(false)
  const [showReviewappWarning, setShowReviewappWarning] = useState(false)
  const [selectedGiftCardDesign, setSelectedGiftCardDesign] = useState<GiftcardDesign>()
  const [isMultiProductOverlay, setIsMultiProductOverlay] = useState<boolean>(false)
  const [multiProductPermalinks, setMultiProductPermalinks] = useState<string[]>([])

  const { data: session } = useSession()
  const { asPath, events, isReady } = useRouter()
  const { data: menus } = useMenus()

  const { data: appConfig } = useAppConfig()

  const forceHideRotatingBanner = appConfig?.rotatingBannerEnabled === false

  const stopShadowingUser = () => get<null>('/api/v2/stop_switching_users')

  useEffect(() => {
    if (!flyoutCartRenderedAlready && isAddToCartModalOpen) {
      setFlyoutCartRenderedAlready(true)
    }
  }, [isAddToCartModalOpen, flyoutCartRenderedAlready])

  useEffect(() => {
    const womensCollection =
      menus.length && menus.filter(menu => menu.text === TOP_LEVEL_MENU.women)[0]
    setWomenMenu(womensCollection)

    const mensCollection = menus.length && menus.filter(menu => menu.text === TOP_LEVEL_MENU.men)[0]
    setMenMenu(mensCollection)

    const nonGenderCollection = menus.filter(
      (m: Menu) => m.text !== TOP_LEVEL_MENU.women && m.text !== TOP_LEVEL_MENU.men,
    )
    setNonGenderMenu(nonGenderCollection)
  }, [setWomenMenu, setMenMenu, setNonGenderMenu, menus])

  useEffect(() => {
    const activeGenderTitle = global.window.sessionStorage.getItem(ACTIVE_GENDER_MENU)
    if (activeGenderTitle) {
      const activeGenderMenu = menus.filter(menu => menu.text === activeGenderTitle)
      setGenderMenuTitle(activeGenderTitle)
      setGenderMenu(activeGenderMenu)
    } else {
      setGenderMenuTitle(DEFAULT_WOMEN_MENU.text)
      setGenderMenu(DEFAULT_WOMEN_MENU)
    }
  }, [setGenderMenu, menus])

  useEffect(() => {
    const activeSubMenuTitle = global.window.sessionStorage.getItem(ACTIVE_SUBMENU_TITLE)
    if (activeSubMenuTitle) {
      const activeSubMenu = menus.filter(menu => menu.text === activeSubMenuTitle)
      setShowTopLevelMenu(false)
      setSubMenu(activeSubMenu)
      setSubMenuTitle(activeSubMenuTitle)
    }
  }, [setSubMenu, menus])

  useEffect(() => {
    const hardLandIfNeeded = (url, { shallow }) => {
      if (!shallow && !isInternal({ href: url })) {
        // hard land, remove event listener
        events.off('routeChangeStart', hardLandIfNeeded)
        global.window.location = url
        // prevent navigation by nextjs router. This will flash an error in dev.
        // https://github.com/vercel/next.js/issues/2476
        // this is a temporary hack to intercept the nextjs route change until nextjs implements a feature to handle this.
        events.emit('routeChangeError')
      }
    }

    events.on('routeChangeStart', hardLandIfNeeded)

    return () => {
      events.off('routeChangeStart', hardLandIfNeeded)
    }
  }, [events])

  useEffect(() => {
    const fireRouteEvent = url => {
      if (url.split('?')[0] !== asPath.split('?')[0]) {
        publishEvent(Event.App.ROUTE, {
          path: url,
          previousRoute: asPath,
        })
      }
    }

    events.on('routeChangeStart', fireRouteEvent)

    return () => {
      events.off('routeChangeStart', fireRouteEvent)
    }
  }, [events, asPath])

  useEffect(() => {
    setIsShadowingUser(session?.isShadowing)
  }, [session])

  useEffect(() => {
    // using useEffect because window is always available in useEffects
    if (window.location.hostname.includes('herokuapp.com')) {
      setShowReviewappWarning(true)
    }
  }, [])

  const contextValue = {
    menus,
    collection,
    product,
    isAddToCartModalOpen,
    isNavigationMenuOpen,
    isGiftcardModalOpen,
    giftcardModalEditingData,
    isBannerSticky,
    isRegionDropdownOpen,
    openAddToCartModal: () => {
      if (isNavigationMenuOpen) {
        setIsNavigationMenuOpen(false)
      }
      setIsAddToCartModalOpen(true)
      publishEvent(Event.Navigation.CART_DROP_DOWN_OPENED)
    },
    closeAddToCartModal: () => setIsAddToCartModalOpen(false),
    setIsNavigationMenuOpen,
    setIsGiftcardModalOpen,
    setGiftcardModalEditingData,
    setIsBannerSticky,
    setIsRegionDropdownOpen,
    genderMenuTitle,
    setGenderMenuTitle,
    genderMenu,
    setGenderMenu,
    womenMenu,
    setWomenMenu,
    menMenu,
    setMenMenu,
    nonGenderMenu,
    setNonGenderMenu,
    subMenu,
    setSubMenu,
    subMenuTitle,
    setSubMenuTitle,
    showTopLevelMenu,
    setShowTopLevelMenu,
    selectedGiftCardDesign,
    setSelectedGiftCardDesign,

    openSubMenu: selectedSubMenuTitle => {
      const subMenuItem = recursiveSubMenuSearch(menus, selectedSubMenuTitle)

      if (subMenuItem) {
        setSubMenu(subMenuItem)
        setSubMenuTitle(selectedSubMenuTitle)
        setShowTopLevelMenu(false)
        global.window.sessionStorage.setItem(ACTIVE_SUBMENU_TITLE, selectedSubMenuTitle)
        publishEvent(Event.Home.TOGGLE_SUBNAV, { subnav: selectedSubMenuTitle })
      }
    },
    closeSubMenu: () => {
      setShowTopLevelMenu(true)

      setTimeout(() => {
        setSubMenu(null)
        setSubMenuTitle(null)
        global.window.sessionStorage.removeItem(ACTIVE_SUBMENU_TITLE)
        publishEvent(Event.Home.TOGGLE_SUBNAV, { subnav: '' })
      }, 400)
    },
  }

  useEffect(() => {
    if (!isReady) return

    const overlayProductHash = asPath.split('#pdpOverlay=')[1]

    const hasMultipleProducts = overlayProductHash?.includes(',')
    const multiProductUrls = overlayProductHash?.split(',')

    if (!hasMultipleProducts && overlayProductHash) {
      setOverlayPermalink(overlayProductHash)
      setOverlaySource('builder_ingrid')
      setShowOverlay(true)
    } else if (hasMultipleProducts && overlayProductHash) {
      const shopTheLookPermalinks = multiProductUrls.slice(0, 5)
      setMultiProductPermalinks(shopTheLookPermalinks)
      setOverlaySource('builder_product_tile')
      setIsMultiProductOverlay(true)
      setShowOverlay(true)
    }
  }, [asPath, isReady])

  const overlayContextValue = {
    showOverlay,
    setShowOverlay,
    overlayPermalink,
    setOverlayPermalink,
    selectedOverlaySize,
    setSelectedOverlaySize,
    overlayError,
    setOverlayError,
    overlayPreviewedSize,
    setOverlayPreviewedSize,
    overlaySource,
    setOverlaySource,
    multiProductPermalinks,
    setMultiProductPermalinks,
    isMultiProductOverlay,
    setIsMultiProductOverlay,
  }

  useEffect(() => {
    if (multiProductPermalinks.length > 1) {
      setIsMultiProductOverlay(true)
    }
  }, [multiProductPermalinks])

  return (
    <NavigationContext.Provider value={contextValue}>
      <SkipLink />
      <OverlayProductContext.Provider value={overlayContextValue}>
        <Trackers />
        {isShadowingUser && (
          <ShadowUserBanner session={session} stopShadowingUser={stopShadowingUser} />
        )}
        {showReviewappWarning && (
          <h1
            style={{
              minHeight: '20vh',
              backgroundColor: 'red',
              color: 'white',
              lineHeight: 'normal',
              fontSize: '32px',
              textAlign: 'center',
            }}
          >
            {`It looks like you're trying to access this as a reviewapp. Access this on ${window.location.hostname.replace(
              'herokuapp.com',
              'everlane.dev',
            )} instead`}
          </h1>
        )}
        {showBanner && !forceHideRotatingBanner && (
          <div
            className={classNames(styles['rotating-banner__container'], {
              [styles['rotating-banner__container--sticky']]: isBannerSticky,
            })}
          >
            <BuilderComponent model="banner" />
          </div>
        )}
        {showNavbar && (
          <Navbar
            showCartIcon={showCartIcon}
            showMenuIcon={showMenuIcon}
            triggerAuthModal={triggerAuthModal}
          />
        )}
        {isGDPRModalOpen && (
          <GDPRModal
            closeModal={() => {
              setIsGDPRModalOpen(false)
              Cookie.set(Cookies.SHOW_GDPR, 'false')
            }}
          />
        )}
        <main style={{ minHeight: '100vh' }} className={bodyContentClass} id="everlane-main">
          {children}
        </main>
        {showFooter && (
          <footer>
            <Footer />
          </footer>
        )}
        {showFlyoutCart && flyoutCartRenderedAlready && (
          <FlyoutCart
            onClose={() => {
              setIsAddToCartModalOpen(false)
              publishEvent(Event.Navigation.CART_DROP_DOWN_CLOSED)
            }}
            isOpen={isAddToCartModalOpen}
          />
        )}
        {isMultiProductOverlay && multiProductPermalinks.length > 1 && <MultiProductOverlay />}
        {!isMultiProductOverlay && overlayPermalink && (
          <ProductOverlay permalink={overlayPermalink} />
        )}
        {isGiftcardModalOpen && (
          <GiftcardModal
            isCardOpen={isGiftcardModalOpen}
            onCloseCard={() => {
              setIsGiftcardModalOpen(false)
            }}
          />
        )}
      </OverlayProductContext.Provider>
    </NavigationContext.Provider>
  )
}

export default Layout
